import gql from 'graphql-tag';

const BANNER_CONTENT_FRAGMENT = gql`
  fragment bannerThumbnailFields on Content {
    id
    thumbnail(fetchFormat: AUTO, crop: FILL, width: 300, devicePixelRatio: 2.0)
  }
`;

const IMAGE_INFO_FRAGMENT = gql`
  fragment imageInfoFields on BannerImageInfo {
    id
    width
    height
    platforms
    maximumFileSize
  }
`;

export const SLOT_LIST_QUERY = gql`
  query slots {
    banner {
      slots {
        totalCount
        edges {
          node {
            id
            name
            maximumCountOfBanner
            status
            categoryId
          }
        }
      }
    }
  }
`;

export const BANNER_LIST_QUERY = gql`
  query banners($slotId: String!) {
    banner {
      banners(slotId: $slotId) {
        totalCount
        edges {
          node {
            id
            indexNo
            title
            actionUris {
              id
              actionUri
              platforms
            }
            beginShowingAt
            endShowingAt
            status
            contents {
              id
              testType
              content {
                ...bannerThumbnailFields
              }
              imageInfo {
                ...imageInfoFields
              }
            }
          }
          cursor
        }
      }
    }
  }
  ${BANNER_CONTENT_FRAGMENT}
  ${IMAGE_INFO_FRAGMENT}
`;
export const BANNER_SLOT_QUERY = gql`
  query bannerSlot($id: ID!) {
    banner {
      slot(id: $id) {
        id
        name
        maximumCountOfBanner
        infos {
          ...imageInfoFields
        }
        categoryId
      }
    }
  }
  ${IMAGE_INFO_FRAGMENT}
`;

export const BANNER_QUERY = gql`
  query banner($id: ID!) {
    banner {
      banner(id: $id) {
        id
        actionUri
        indexNo
        title
        description
        beginShowingAt
        endShowingAt
        createdAt
        updatedAt
        status
        actionUris {
          id
          actionUri
          platforms
        }
        contents {
          id
          testType
          content {
            id
            uri
            width
            height
          }
          imageInfo {
            ...imageInfoFields
          }
        }
      }
    }
  }
  ${IMAGE_INFO_FRAGMENT}
`;

export const BANNER_CURRENTS_BANNERS_QUERY = gql`
  query currents($slotId: String!, $at: DateTime, $platform: ClientPlatform) {
    banner {
      currents(slotId: $slotId, at: $at, platform: $platform) {
        id
      }
    }
  }
`;
